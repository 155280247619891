<header class="navigation">
	<div class="header-top ">
		<div class="container">
			<div class="row justify-content-between align-items-center">
				<div class="col-lg-5 col-md-4 text-center text-lg-left text-md-left">
					<div class="header-top-info">
						<a><strong>Our Services: </strong></a>
						<a href="https://resources.ngoworld.ng" target="_blank"><i
								class="icofont-library mr-2"></i><span>Resources</span></a>
						<a href="https://community.ngoworld.ng" target="_blank"><i
								class="icofont-people mr-2"></i>
								<span>Online Community</span></a>
						<a href="https://jobs.ngoworld.ng" target="_blank">
							<i class="icofont-search-job  mr-2"></i><span>Jobs</span></a>
					</div>
				</div>
				<div class="col-lg-7 col-md-8">
					<div class="header-top-right text-center text-lg-right text-md-right mt-3 mt-lg-0">
						<a class="top-social" href="https://twitter.com/INGOforum_ng" target="_blank"><i class="icofont-twitter"></i></a>

						<a class="top-social" href="https://www.youtube.com/channel/UCn6-HlqYBJgJnzTAOfGYA4g" target="_blank"><i
								class="icofont-youtube-play"></i></a>
													<a routerLink="our-members" class="top-btn">Our Members</a>
							<a href="https://ingoforum.ng/join"  target="_blank" class="top-btn">Become a Member</a>
						<a href="https://blog.ingoforum.ng" target="_blank" class="top-btn">Blog</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<nav class="navbar navbar-expand-lg navigation" id="navbar">
		<div class="container">
			<a class="navbar-brand" href="index.html">
				<img src="/assets/images/Full-NIF-Logo.svg" alt="" class="img-fluid">
			</a>

			<button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
				data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
				aria-label="Toggle navigation">
				<span class="ti-menu"></span>
			</button>

			<div class="collapse navbar-collapse text-center" id="navbarsExample09">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item active">
						<a class="nav-link" routerLinkActive="active"  routerLink="/">Home <span class="sr-only">(current)</span></a>
					</li>
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLinkActive="active" href="https://ingoforum.ng/about-us" id="dropdown03"
							data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About<span
								class="ml-1">+</span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown07">
							<li><a class="dropdown-item" href="https://ingoforum.ng/about-us#who-we-are/">Who We Are</a>
							</li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/about-us#overnance-and-structure/"
									>Governance & Structure</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/about-us#our-team/">Our Team</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/about-us#hosting-and-funding/">Hosting
									& Funding</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/contact-us">Contact Us</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/careers">Careers </a></li>
						</ul>
					</li>

					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLink="our-work" id="dropdown03" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">Our Work<span class="ml-1">+</span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown03">
							<li><a class="dropdown-item" href="https://ingoforum.ng/our-work#coordination-for-better-delivery/">Coordination for Better Delivery</a>
							</li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/our-work#our-initiative/">Our
									Initiatives</a></li>
						</ul>
					</li>



					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLinkActive="active" href="https://ingoforum.ng/membership" id="dropdown04"
							data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Membership <span
								class="ml-1">+</span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown04">
							<li><a class="dropdown-item" href="https://ingoforum.ng/membership#who-is-eligible/">Who is
									eligible?</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/membership#why-should-i-join-nif/">Why
									should I join NIF? </a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/membership#how-can-i-join-nif-as-an-observer/">How can I
									join NIF as an Observer?</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/membership#how-long-can-i-be-a-member/">How long
									can I be a member / an observer?</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/membership#grounds-to-discontinue-membership/">Grounds to discontinue my membership </a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/membership#who-are-our-members/">Who are
									our members?</a></li>

						</ul>
					</li>

					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" routerLinkActive="active" id="dropdown05" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">Coordination<span class="ml-1">+</span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown05">
							<li><a class="dropdown-item" href="https://ingoforum.ng/ingo-coordination-platforms">INGO Coordination
									Platforms</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/humanitarian-coordination-architecture">
									Humanitarian Coordination Architecture</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/government-coordinating-bodies">Government
									Coordinating Bodies</a></li>
						</ul>
					</li>
					<!-- mongorestore --archive="ingoforumdump" --nsFrom='ingo.*' --nsTo='ingoforum.*' -->
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#" id="dropdown06" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">Media<span class="ml-1">+</span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown06">
							<li><a class="dropdown-item" href="https://ingoforum.ng/news">News</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/publications">Publications</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/multimedia">Multimedia</a></li>
							<li><a class="dropdown-item" href="https://ingoforum.ng/media-contacts">Media Contacts</a></li>
						</ul>
					</li>
					<!-- <li class="nav-item"><a class="nav-link" href="contact.html">Galleries</a></li> -->
					<li class="nav-item active">
						<a class="nav-link"  href="https://data.ingoforum.ng" target="_blank"
						fragment="who-is-eligible/">Member Interactive 3W</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</header>

<!-- Header Close -->