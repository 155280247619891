<!-- footer Start -->
<footer class="section-section-bg section ingoforum-footer">
	<div class="container">
		<div class="row">

			<div class="col-lg-2 col-md-6 col-sm-6">
				<div class="widget footer-widget">
					<h4 class="text-capitalize mb-4 content-title  text-white" >Quick Links</h4>

					<ul class="list-unstyled footer-menu content-body">
						<li><a  href="https://ingoforum.ng/about-us#who-we-are/">Who we are</a></li>
						<li><a  href="https://ingoforum.ng/about-us#our-team/">Our Team</a></li>
						<li><a  href="https://ingoforum.ng/members" >Our Members</a></li>
						<li><a  href="https://ingoforum.ng/our-work#coordination-for-better-delivery/">Coordination</a></li>
						<li><a  href="https://ingoforum.ng/newsletters">Newsletter</a></li>
						<li><a href="https://blog.ingoforum.ng">Blogs</a></li>
						<li><a href="#">FAQs</a></li>
					</ul>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="widget footer-widget">
					<h4 class="text-capitalize mb-4 content-title  text-white">NIF Services</h4>
					<ul class="list-unstyled footer-menu  content-body">
						<li><a  target="_blank" href="https://resources.ngoworld.ng">Resources</a></li>
						<li><a  target="_blank" href="https://community.ngoworld.ng">Online Community</a></li>
						<li><a target="_blank" href="https://jobs.ngoworld.ng">Jobs</a></li>
					</ul>
				</div>
			</div>

			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="footer-widget widget">
					<h4 class="text-capitalize mb-4 content-title  text-white">CSO Networks</h4>
					<ul class="list-unstyled footer-menu  content-body">
						<li><a href="https://wihrini.org/" target="_blank"> Women in Humanitarian Response in Nigeria</a></li>
						<li><a href="https://nnngo.org/" target="_blank">Nigeria Network of NGOs</a></li>
					</ul>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="footer-widget widget">
					<h4 class="text-capitalize mb-4 content-title text-white">Connect With Us</h4>
					<p>No. 2 Yabo Close, Off Jere Street, <br> Garki II, Abuja - FCT, Nigeria</p>

					<ul class="list-unstyled footer-menu content-body mt-4">
						<li><a href="#"><i class="icofont-phone"></i>+234 908 737 4910</a></li>
						<li><a href="#"><i class="icofont-email"></i>info@ingoforum.ng</a></li>
					</ul>
					<ul class="list-inline content-body footer-socials">
						<!-- <li class="list-inline-item"><a href="https://www.facebook.com/ingoforum"><i class="icofont-facebook"></i></a></li> -->
						<li class="list-inline-item"><a target="_blank" href="https://twitter.com/INGOforum_ng"><i
									class="icofont-twitter"></i></a></li>
						<li class="list-inline-item"><a target="_blank"
								href="https://www.youtube.com/channel/UCn6-HlqYBJgJnzTAOfGYA4g"><i
									class="icofont-youtube-play"></i></a></li>
						<!-- <li class="list-inline-item"><a href="https://www.pinterest.com/ingoforum/"><i class="icofont-linkedin"></i></a></li> -->
					</ul>
				</div>
			</div>


		</div>

	</div>
</footer>

<div class="intro">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-6">
				<h3 class="text-capitalize my-4 ">Hosted By</h3>
				<div class="clients-wrap">
					<a href="https://immap.org/nigeria/" target="_blank" rel="noopener noreferrer">
						<img src="/assets/images/members/immap.png" alt="iMMAP" class="img-fluid">
					</a>
				</div>
			</div>
			<div class="col-lg-6">
				<h3 class="text-capitalize my-4">Funded By</h3>
				<div class="clients-wrap funded-by ">
					<a href="#" *ngFor="let member of donors">
						<div class="members-item-wrap">
							<div class="team-item position-relative">
															<a href="{{member.link }}" class="link" target="_blank" rel="noopener noreferrer">
								<img src="{{member?.src}}" alt="" class="img-fluid">
								</a>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="intro reserved" style="background-color: rgba(0, 0, 0, 0.05);">
	<div class="container">
		<div class="text-center p-4" >
			<a  target="_blank" href="https://ingoforum.ng/terms-and-conditions">Terms of Use</a>  | 		<a target="_blank" href="https://ingoforum.ng/privacy"> Privacy Notice</a> <br>
			Nigeria INGO Forum. All rights reserved © {{ currentYear }} Copyright
		  </div>
	</div>
</div>