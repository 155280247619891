import { Injectable } from '@angular/core';
// import { Token } from '../../interfaces/token.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class BlogService {
	// private userSubject: BehaviorSubject<any>;
	// public user: Observable<any>;
	apiUrl: string = environment.API_SERVER;

	constructor(private _httpClient: HttpClient) {}

	create(payload): Observable<any> {
		return this._httpClient.post<any>(this.apiUrl + '/public/blog', payload.body);
	}

	edit(payload): Observable<any> {
		return this._httpClient.put(`${this.apiUrl}/public/blog`, payload.body);
	}

	getBlogs(payload): Observable<any> {
		return this._httpClient.get(`${this.apiUrl}/public/blog`, { params: payload.params });
	}

	incrementBlog(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/increment-resource/${payload.id}`, {
			params: payload.param
		});
	}

	getBlog(payload): Observable<any> {
		return this._httpClient.get(`${this.apiUrl}/public/blog/${payload.slug}`);
	}
}
