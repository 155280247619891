import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: [ './home.component.scss' ]
})
export class HomeComponent implements OnInit {
	posts: Array<any> = [
		{
			image: '/assets/images/blog/blog_2.jpg',
			date: 'January 3, 2019',
			title: 'A place where start new life with peace',
			description: 'Aspernatur obcaecati unde, quasi nihil neque, voluptatem. Consectetur.'
		},
		{
			image: '/assets/images/blog/blog_2.jpg',
			date: 'January 3, 2019',
			title: 'A place where start new life with peace',
			description: 'Aspernatur obcaecati unde, quasi nihil neque, voluptatem. Consectetur.'
		},
		{
			image: '/assets/images/blog/blog_2.jpg',
			date: 'January 3, 2019',
			title: 'A place where start new life with peace',
			description: 'Aspernatur obcaecati unde, quasi nihil neque, voluptatem. Consectetur.'
		}
	];
	publications: Array<any> = [
		{
			image: '/assets/images/publication/Background/Newsletter.png',
			date: 'January 3, 2019',
			title: 'A place where start new life with peace',
			description: 'Aspernatur obcaecati unde, quasi nihil neque, voluptatem. Consectetur.'
		},
		{
			image: '/assets/images/publication/Background/Report_Cover.jpg',
			date: 'January 3, 2019',
			title: 'A place where start new life with peace',
			description: 'Aspernatur obcaecati unde, quasi nihil neque, voluptatem. Consectetur.'
		},
		{
			image: '/assets/images/publication/Background/Snapshot.png',
			date: 'January 3, 2019',
			title: 'A place where start new life with peace',
			description: 'Aspernatur obcaecati unde, quasi nihil neque, voluptatem. Consectetur.'
		}
	];
	medias: Array<any> = [
		{ src: '/assets/images/gallery/1.jpg' },
		{ src: '/assets/images/gallery/2.jpg' },
		{ src: '/assets/images/gallery/3.jpg' },
		{ src: '/assets/images/gallery/4.jpg' },
		{ src: '/assets/images/gallery/5.jpg' },
		{ src: '/assets/images/gallery/6.jpg' },
		{ src: '/assets/images/gallery/1.jpg' },
		{ src: '/assets/images/gallery/2.jpg' }
	];
	members: Array<any> = [
		{ src: '/assets/images/members/acf_aah.png' },
		{ src: '/assets/images/members/acted.png' },
		{ src: '/assets/images/members/actionaid.png' },
		{ src: '/assets/images/members/alert.png' },
		{ src: '/assets/images/members/alima.png' }
	];
	donors: Array<any> = [
		{ src: '/assets/images/donors/EU_CP-HA_EN_Horizontal.png' },
		{ src: '/assets/images/donors/Horizontal_CMYK_294_Vector.png' },
		{ src: '/assets/images/donors/Membership-Contributions-Logo.png' },
		{ src: '/assets/images/donors/SDC-Logo.png' }
	];
	statics: Array<any> = [];
	covids: Array<any> = [
		{
			name: 'Confirmed Cases',
			count: 141447
		},
		{
			name: 'Active Cases',
			count: 23998
		},
		{
			name: 'Recoveries',
			count: 115755
		},
		{
			name: 'Deaths',
			count: 1694
		}
	];

	constructor() {}

	ngOnInit(): void {}
}
