import { Component, OnInit } from '@angular/core';
import { BlogService, OptionService } from 'src/app/services';
import { AppFilter } from 'src/app/models';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

declare var $: any;
@Component({
	selector: 'app-detail',
	templateUrl: './detail.component.html',
	styleUrls: [ './detail.component.scss' ]
})
export class BlogDetailComponent implements OnInit {
	baseUrl = environment.API_SERVER;
	blog: any;
	tags: Array<any> = [ 'General', 'Community' ];
	categories: Array<any> = [];
	latest: Array<any> = [];
	filter: AppFilter;
	pending: boolean;
	error: any;
	success: any;
	totalItems: number;
	currentPage: number;
	rows: number;
	categoryId: string;
	tagId: string;
	blogId: string;
	fileSrc: any;
	constructor(
		private activatedRoute: ActivatedRoute,
		private optionSvc: OptionService,
		private blogSvc: BlogService,
		private title: Title
	) {
		this.filter = new AppFilter({ sidx: 'publishedat' });
		this.filter.search = false;
		this.activatedRoute.params.subscribe((res) => {
			this.blogId = res['slug'];
			if (this.blogId) {
				this.searchRecords(this.blogId);
			}
		});
		// this.getPage(1);

		this.getLatests();
		this.getOptionTypes();
	}

	getBlog() {
		this.pending = true;
		this.blogSvc.getBlog({ slug: this.blogId }).subscribe(
			(res) => {
				this.pending = false;
				this.blog = res;
			},
			(error) => {
				this.pending = false;
				this.error = error.error.split('=').pop();
			}
		);
	}

	getLatests() {
		let filter = new AppFilter({ sidx: '-createdat', rows: 3 });
		this.blogSvc.getBlogs({ params: this.filter }).subscribe(
			(res) => {
				this.pending = false;
				this.latest = res['resources'];
			},
			(error) => {
				this.pending = false;
				this.error = error.error.split('=').pop();
			}
		);
	}
	getCategories() {}

	getPage(page) {
		this.filter.page = page;
		this.pending = true;
		this.blogSvc.getBlogs({ params: this.filter }).subscribe(
			(res) => {
				this.pending = false;
				this.blog = res['resources'] ? res['resources'][0] : null;
				this.title.setTitle(`${res['resources'][0]['title']} | Nigeria INGO Forum`);
				this.incrementView('view');
			},
			(error) => {
				this.pending = false;
				this.error = error.error.split('=').pop();
			}
		);
	}

	//handle option search
	searchRecords(search) {
		let filter = this.filterComposer(search);
		this.filter.filters = JSON.stringify(filter);
		this.getPage(1);
	}
	//handle sorting records
	typeRecords(search) {
		this.categoryId = search;
		let filter = this.filterComposer(null);
		this.filter.filters = JSON.stringify(filter);
		this.getPage(1);
	}

	typeTags(search) {
		this.tagId = search;
		let filter = this.filterComposer(null);
		this.filter.filters = JSON.stringify(filter);
		this.getPage(1);
	}

	//handle sorting records
	sortRecords(search) {
		this.filter.sord = search;
		this.getPage(1);
	}

	//handles rows display
	displayRecords(row) {
		this.filter['rows'] = row;
		this.filter.page = 1;
		this.getPage(1);
	}

	nextPage(page: number) {
		//get and assign current page
		this.currentPage = page;
		this.getPage(this.currentPage);
	}

	//group query
	filterComposer(query) {
		let mongofilter: { [id: string]: any } = {};
		let name: { [id: string]: any } = {};
		let description: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};

		if (this.categoryId) {
			type['$eq'] = this.categoryId;
			mongofilter['categoryid'] = type;
		}

		if (this.tagId) {
			// type['$eq'] = this.categoryId;
			mongofilter['tags'] = this.tagId;
		}
		// bson.M{"$lookup": bson.M{"from": "subjectcategory", "localField": "category", "foreignField": "_id", "as": "category"}},
		// bson.M{"$unwind": "$category"},

		if (query !== null) {
			type['$eq'] = query;
			mongofilter['slug'] = type;
		}

		return mongofilter;
	}
	incrementView(type) {
		this.pending = true;
		this.blogSvc.incrementBlog({ id: this.blogId, param: { type: type } }).subscribe(
			(res) => {},
			(error) => {
				this.error = error.error.split('=').pop();
			}
		);
	}

	viewFile(resource) {
		this.fileSrc = `${environment.API_SERVER}/public/assets?media=${resource.attachment['path']}`;
		$('#fileViewerModal').modal('show');
	}

	downloadFile(attachment) {
		let url = `${environment.API_SERVER}/public/assets?media=${attachment['path']}`;
		window.open(url, '_blank');
		this.incrementView('download');
	}

	ngOnInit(): void {}
	getOptionTypes() {
		let filter = new AppFilter({ sidx: '-order', rows: 1000, sord: 'des' });
		let mongofilter: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};
		type['$eq'] = 'blog_category';
		mongofilter['optiontypeid'] = type;
		filter.search = false;
		filter.filters = JSON.stringify(mongofilter);
		this.optionSvc.getAllOption({ param: filter }).subscribe((res) => {
			this.categories = res.options;
		});
	}
}
