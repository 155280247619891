


<!-- Slider Start -->
<section class="">
	 <video autoplay="" loop="" muted="" poster="" playsinline="" style="width: 100%;">
	 	  <!-- <source src="https://immap.org/wp-content/uploads/2020/07/IMMAP-BANNER-071320-REV-1.mp4" type="video/mp4"> -->
				   <source src="/assets/banner.webm" type="video/webm">
						<!-- <source src="/assets/animated-slide.webm" type="video/webm"> -->
					</video>
</section>

<!-- Section Intro Start -->

<section class="section intro">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6">
				<div class="section-title">
					<span class="text-color">What we do</span>
					<h2 class="mt-3 content-title">We Believe that We can <br>Save More Lifes with you</h2>
				</div>
			</div>
			<div class="col-lg-6">
				<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt, dicta, iure. Esse quasi labore aperiam, dolorem amet voluptas soluta asperiores nostrum voluptate molestias numquam similique. Voluptate natus corporis ex, distinctio.</p>
			</div>
		</div>
		<div class="row justify-content-center text-center">
			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="intro-item mb-5 mb-lg-0">
					<img src="/assets/images/functions/coordination.png" alt="" class="img-fluid w-30">
					<h4 class="mt-4 mb-3">Coordination</h4>
					<p>We facilitate and ensure productive relationships and mutual understanding among members, and with external stakeholders</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="intro-item mb-5 mb-lg-0">
					<img src="/assets/images/functions/representation.png" alt="" class="img-fluid w-30">
					<h4 class="mt-4 mb-3">Representation</h4>
					<p>We represent and act in members' interests in external meetings and coordination mechanisms</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="intro-item">
					<img src="/assets/images/functions/information-sharing.png" alt="" class="img-fluid w-30">
					<h4 class="mt-4 mb-3">Information Sharing</h4>
					<p>We keep our members and external stakeholders informed through transparent flow of relevant and critical information</p>
				</div>
			</div>
		</div>
		<div class="row justify-content-center text-center">
			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="intro-item mb-5 mb-lg-0">
					<img src="/assets/images/functions/advocacy.png" alt="" class="img-fluid w-30">
					<h4 class="mt-4 mb-3">Policy & Advocacy</h4>
					<p>We mobilise members on collective positioning and joint action on critical issues</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="intro-item mb-5 mb-lg-0">
					<img src="/assets/images/functions/access.png" alt="" class="img-fluid w-30">
					<h4 class="mt-4 mb-3">Access & Community Engagement</h4>
					<p>We navigate relevant apparatus, including engaging directly with affected communities to improve humanitarian access and accountability to affected population</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-6">
				<div class="intro-item">
					<img src="/assets/images/functions/partnership.png" alt="" class="img-fluid w-10">
					<h4 class="mt-4 mb-3">Partnership & Capacity Building</h4>
					<p>We commit to strengthening collaborative efforts and continues capacity development</p>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Section Intro END -->
<section class="section video">
	<div class="container">
		<div class="row ">
			<div class="col-lg-8">
				<div class="video-content  mb-5 mb-lg-0">
					<h2 class="mt-3 mb-5 position-relative text-lg text-white">Key Membership <br>Figure</h2>
				</div>
			</div>
		</div>
		<!-- <div class="row">
			<div class="col-lg-12">
				<div class="video-block">
					<div class="img-block">
						<img src="/assets/images/bg/bg-3.jpg" alt="" class="img-fluid">
					</div>
					<a data-video-id="sXoKSD8QJEA" class="videoplay">
						<i class="icofont-ui-play"></i>
					</a>
				</div>
			</div>
		</div> -->

		<div class="counter-section">
			<div class="row">
				<div class="col-lg-2 col-md-6 col-sm-6">
					<div class="counter-item-2 mb-5 mb-lg-0 pt-5">
						<span class="counter-stat  text-color">54</span>
						<p>Members</p>
					</div>
				</div>
				<div class="col-lg-2 col-md-6 col-sm-6">
					<div class="counter-item-2 mb-5 mb-lg-0 pt-5">
						<span class="counter-stat font-weight-bold text-color">9</span>
						<p>Observers</p>
					</div>
				</div>
				<div class="col-lg-2 col-md-6 col-sm-6">
					<div class="counter-item-2 mb-5 mb-lg-0 pt-5">
						<span class="counter-stat  text-color">29</span>
						<p>Reporting Member</p>
					</div>
				</div>
				<div class="col-lg-2 col-md-6 col-sm-6">
					<div class="counter-item-2 pt-5">
						<span class="counter-stat text-color">148</span>
						<p >Projects</p>
					</div>
				</div>
					<div class="col-lg-2 col-md-6 col-sm-6">
					<div class="counter-item-2 mb-5 mb-lg-0 pt-5">
						<span class="counter-stat  text-color">16</span>
						<p>States</p>
					</div>
				</div>
				<div class="col-lg-2 col-md-6 col-sm-6">
					<div class="counter-item-2 pt-5">
						<span class="counter-stat text-color">177</span>
						<p >Local Gov't Areas</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>


<section class="section blog-wrap">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
				<div class="row">
					<div class="col-lg-12">
						<h2 class="mt-3 mb-4 position-relative content-title">Latest News.</h2>
				</div>
				</div>
                <div class="row">
				<div class="col-lg-4 col-md-6 mb-3" *ngFor="let post of posts">
						<div class="card blog-item">
					<img src="{{post?.image}}" alt="" class="img-fluid">

					<div class="card-body mt-2">
						<span class="text-sm text-color text-uppercase font-weight-bold">{{post?.date}}</span>
						<h3 class="mb-3"><a href="blog-single.html" class="">{{post?.title}}</a></h3>
						<!-- <p>{{post?.description}}.</p> -->
					</div>
				</div>
			</div>
			</div>
				<div class="row mb-5">
					<div class="col-lg-12">
				<a routerLink="/blog"  class="btn btn-main animated fadeInUp rounded">More News</a>
				</div>
				</div>

			<!--Publications  -->
			<div class="row">
					<div class="col-lg-12">
						<h2 class="mt-3 mb-4 position-relative content-title">Latest Publications.</h2>
				</div>
				</div>
                <div class="row">
				<div class="col-lg-4 col-md-6 mb-3 mb-lg-0" *ngFor="let pub of publications">
				<div class="card blog-item">
					<img src="{{pub?.image}}" alt="" class="img-fluid">
					<div class="card-body mt-2">
						<span class="text-sm text-color text-uppercase font-weight-bold">{{pub?.date}}</span>
						<h3 class="mb-3"><a href="blog-single.html" class="">{{pub?.title}}</a></h3>
						<!-- <p>{{pub?.description}}</p> -->
					</div>
				</div>
			</div>
			</div>
				<div class="row my-5">
					<div class="col-lg-12">
				<a routerLink="/publications"   class="btn btn-main animated fadeInUp rounded">More Publications</a>
				</div>
				</div>

            </div>
            <div class="col-lg-4">
                <div class="sidebar-wrap mt-5 mt-lg-0">
					<div class="sidebar-widget search p-4 mb-3 " id="covid">
						<div class="row">
							<div class="col-lg-12">
								<h2 class="mt-3 mb-4 position-relative content-title" id="covid-title">COVID-19 In Nigeria</h2>
						    </div>
						</div>

						<div class="row">
							<div class="col-lg-12" *ngFor="let covid of covids">
								<div class="counter-item-2 mb-5 mb-lg-0 pt-5 text-center">
									<span class="counter-stat  text-color" id="covid-count">{{covid?.count}}</span>
									<p id="covid-name"> {{covid?.name}}</p>
								</div>
							</div>
						</div>
					</div>

						<div class="sidebar-widget  p-4 mb-3 " id="community" >
						<div class="row">
							<div class="col-lg-12">
								<h2 class="mt-3 mb-4 position-relative content-title" >Join the virtual <br/>NGO community</h2>
							</div>
						</div>
							<div class="row mb-5">
								<div class="col-lg-12">
						     	<a href="#">Register</a> &nbsp; &nbsp; |&nbsp; &nbsp; <a href="#" target="_blank" >Login</a>
							</div>
							</div>
					</div>
				</div>
            </div>
        </div>


    </div>
</section>


	<!--Multi media  -->
	<div class="cta-block section">
	<div class="container">
		<div class="row justify-content-center ">
			<!-- <div class="row">
			<div class="col-lg-12">
				<h2 class="mt-3 mb-4 position-relative content-title">Multimedia.</h2>
		</div>
		</div> -->
		<div class="gallery-wrap">
			<div class="row" >
				<div class="col-lg-3" *ngFor="let media of medias">
					<div class="gallery-item">
						<a href="{{media?.src}}" class="gallery-popup">
							<img src="{{media?.src}}" alt="" class="img-fluid w-100">
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-5">
			<div class="col-lg-12 text-center">
				<a routerLink="/gallery"  class="btn btn-main animated fadeInUp rounded">More Media</a>
				</div>
		</div>
			</div>
	</div>
		</div>


<section>
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="section-divider"></div>
			</div>
		</div>
	</div>
</section>

<div class="volunteer section ">
	<div class="container">
		<div class="row">
			<div class="col-lg-7 col-md-12 col-sm-12">
				<div class="volunteer-content">
					<!-- <img src="/assets/images/bg/image-5.jpg" alt="" class="img-fluid">
					<h2 class="text-lg mb-5 mt-3">We can’t help everyone, but everyone can help someone</h2>
					<p>Assumenda reiciendis delectus dolore incidunt molestias omnis quo quaerat voluptate, eligendi perspiciatis ipsa laudantium nesciunt officia, odit nemo quidem hic itaque. Fugiat.</p> -->

					<h2 class="mt-5 mb-4">Our Members</h2>
					<div class="clients-wrap">
						<a href="#" *ngFor="let member of members">
							<img src="{{member.src}}" alt="" class="img-fluid">
						</a>
					</div>


					<h2 class="mt-5 mb-4">Our Supporter</h2>
					<div class="clients-wrap">
						<a href="#" *ngFor="let member of donors">
							<img src="{{member?.src}}" alt="" class="img-fluid">
						</a>
					</div>
				</div>
			</div>

			<div class="col-lg-5 col-md-12 col-sm-12">
				<div class="volunteer-form-wrap">
					<span class="text-white">Subscribe</span>
					<h2 class="mb-5 text-lg text-white">Receive Weekly Update</h2>
					<form action="#" class="volunteer-form">
						<div class="form-group">
							<input type="text" class="form-control" placeholder="First Name">
						</div>
								<div class="form-group">
							<input type="text" class="form-control" placeholder="Last Name">
						</div>
						<div class="form-group">
							<input type="email" class="form-control" placeholder="Emaill Address">
						</div>
						<div class="form-group">
							<input type="text" class="form-control" placeholder="Phone Number">
						</div>
						<!-- <div class="form-group">
							<input type="text" class="form-control" placeholder="Adress ">
						</div> -->
						<!-- <div class="form-group">
							<input type="text" class="form-control" placeholder="Occupation">
						</div>
						<div class="form-group">
							<textarea name="#" id="#" cols="30" rows="6" class="form-control" placeholder="Your Message"></textarea>
						</div> -->

						<a href="#" class="btn btn-main rounded mt-3">Submit</a>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
