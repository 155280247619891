import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent, ContactComponent, BlogComponent, BlogDetailComponent } from './pages';

const routerOptions: ExtraOptions = {
	anchorScrolling: 'enabled',
	useHash: false,
	onSameUrlNavigation: 'reload',
	scrollPositionRestoration: 'enabled',
	scrollOffset: [ 0, 64 ],
	enableTracing: true
};

const routes: Routes = [
	{
		path: '',
		component: BlogComponent
	},
	{
		path: 'contact-us',
		component: ContactComponent
	},

	{
		path: 'create',
		component: BlogDetailComponent
	},
	{
		path: ':slug',
		component: BlogDetailComponent
	},
	{
		path: ':slug/edit',
		component: BlogDetailComponent
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, routerOptions) //ExtraOptions Anchor text
	],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
