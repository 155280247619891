
<section class="page-title bg-1">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block text-center">
          <span class="text-white">Our blog</span>
          <h1 class="text-capitalize mb-4 text-lg">Blog articles</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section blog-wrap">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="d-flex justify-content-center" *ngIf="pending">
                    <div class="spinner-grow"   style="width: 5rem; height: 5rem;"role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 mb-5 alert alert-warning text-center" role="alert"  *ngIf="!pending && (blogs && blogs.length < 1)">
                       <h3>
                           <!-- <i class="icofont-error "></i> -->
                            No Record Found</h3>
                    </div>
					<div class="col-lg-6 col-md-6 mb-5" *ngFor="let blog of blogs | paginate: { itemsPerPage: rows, currentPage: currentPage,totalItems: totalItems}">
						<div class="blog-item-content">
							<img *ngIf="blog.image" src="{{baseUrl}}/public/assets?media={{blog?.image}}"  alt="" class="img-fluid">
	                         <img *ngIf="blog.image" src="/assets/images/"  alt="" class="img-fluid">
							<div class="blog-item-content-content mt-3">
								<span class="text-sm text-color text-uppercase font-weight-bold">{{blog?.published_at |date}}</span>

								<h5 class="mb-3"><a routerLink="/{{blog?.slug}}">{{blog?.name}}</a></h5>
								<p class="mb-4">{{blog.summary | truncate:'150'}}</p>

								<a routerLink="/{{blog?.slug}}" class="btn btn-small btn-main rounded">Learn More</a>
							</div>
						</div>
					</div>
				</div>
			</div>
            <div class="col-lg-4">
                <div class="sidebar-wrap mt-5 mt-lg-0">
	<div class="sidebar-widget search p-4 mb-3 ">
		<input type="text" class="form-control" placeholder="search"  [formControl]="search" >
		<a href="#"><i class="icofont-search"></i></a>
	</div>

	<div class="sidebar-widget latest-post p-4 mb-3">
		<h5>Latest Posts</h5>

        <div class="border-bottom py-3"  *ngFor="let blog of latest">
            <span class="text-sm text-muted">{{blog?.published_at |date}}</span>
            <h5 class="my-2"><a routerLink="/blog/{{blog?.slug}}" >{{blog?.name}}</a></h5>
        </div>
	</div>

	<div class="sidebar-widget categories p-4 mb-3">
		<h5 class="mb-4">Category</h5>
		<ul class="list-unstyled">
			<li  *ngFor="let cat of categories" (click)="typeRecords(cat.id)"><a routerLink="/" [queryParams]="{category:cat.name}" ><i class="icofont-folder mr-3"></i>{{cat.name}}</a></li>
			<!-- <li><a href="#"><i class="icofont-folder mr-3"></i>Shelter</a></li> -->
		</ul>
	</div>
	<div class="sidebar-widget tags p-4 mb-3">
		<h5 class="mb-4">Tags</h5>
		<a  *ngFor="let tag of tags" routerLink="/" [queryParams]="{tag:tag}" (click)="typeTags(tag)">{{tag}}</a>
	</div>

</div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-8">
                    <!-- <pagination-controls
                    responsive="true"
                    previousLabel="Prev"
                    nextLabel="Next"
                    (pageChange)="nextPage($event)">
                    </pagination-controls> -->
             <nav class="pagination py-2 d-inline-block">
                <pagination-template #p="paginationApi"
                           (pageChange)="nextPage($event)"
                     >

                    <div class="nav-links">
                        <div class="page-numbers"  class="pagination-previous" [class.disabled]="p.isFirstPage()">
                            <a class="page-numbers" *ngIf="!p.isFirstPage()" (click)="p.previous()"><i class="icofont-long-arrow-left"></i> </a>
                        </div>
                        <div  *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                            <a  class="page-numbers" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </a>
                            <div  *ngIf="p.getCurrent() === page.value">
                                <a  class="page-numbers current" >{{ page.label }}</a>
                            </div>
                        </div>

                        <div class="pagination-next" [class.disabled]="p.isLastPage()">
                            <a  class="page-numbers" *ngIf="!p.isLastPage()" (click)="p.next()"> <i class="icofont-long-arrow-right"></i> </a>
                        </div>
                          </div>


                </pagination-template>
      </nav>
                <!-- <nav class="pagination py-2 d-inline-block">
                    <div class="nav-links">
                        <a class="page-numbers current" href="#">1</a>
                        <a class="page-numbers" href="#">2</a>
                        <a class="page-numbers" href="#">3</a>
                        <a class="page-numbers" href="#"><i class="icofont-long-arrow-right"></i></a>
                    </div>
                </nav>  -->
            </div>
        </div>
    </div>
</section>