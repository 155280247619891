

<section class="page-title bg-1">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block text-center">
          <span class="text-white">Blog details</span>
          <h6 class="text-capitalize mb-4 text-lg text-white">{{blog?.name}}</h6>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="section blog-wrap">
    <div class="container">
        <div class="row">

						<div class="col-lg-8">
							        <div class="d-flex justify-content-center" *ngIf="pending">
                    <div class="spinner-grow"   style="width: 5rem; height: 5rem;"role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                </div>
							<div class="row">
								<div class="col-lg-12 mb-5">
									<div class="single-blog-item">

										<div class="blog-item-content mt-3">
											<h2 class="mt-3"><a >{{blog?.name}}</a></h2>

											<div class="blog-item-meta py-1 px-2 ">
												<span class="text-black mr-3"><span class="text-muted mr-1"><i class="icofont-folder"></i> CATEGORY:</span> {{blog?.category_id?.name}}</span>
												<span class="text-black mr-3"><span class="text-muted mr-1"><i class="icofont-calendar mr-1"></i> POSTED ON:</span> {{blog?.published_at | date}}</span>
												 <span class="text-black mr-3"><span class="text-muted mr-1"><i class="icofont-eye-alt"></i> VIEWS:</span> {{blog?.view_count}}</span>
												  											 <span class="text-black mr-3"><span class="text-muted mr-1"><i class="icofont-download"></i> DOWNLOADS:</span> {{blog?.download_count}}</span>
												<!-- <span class="text-black mr-3"><span class="text-muted mr-1"><i class="icofont-ui-user"></i> AUTHOR:</span> Musa John</span> -->
												<!-- <span class="text-muted mr-3"><i class="icofont-comment mr-2"></i>5 Comments</span> -->
											</div>
											<div class="blog-item-meta py-1 px-2 mb-4">
												 <!-- class="sidebar-widget tags p-4 mb-3" -->
											<span class="mr-2">Tags</span>	<span class="tags" *ngFor="let tag of blog?.tags">{{tag}}</span>
											</div>

										<div [innerHtml]="blog?.description"></div>

											<div class="row mt-3 mb-3"  *ngFor="let file of blog?.attachments">
										<div class="col-lg-1 file-name">
												<i class="icofont-file-pdf big" *ngIf="file.extension == '.pdf'" ></i>
												<i class="icofont-file-pdf big" *ngIf="file.extension == '.txt'" ></i>
												<i class="icofont-image big" *ngIf="file.extension == '.jpg'" ></i>
												<i class="icofont-image big" *ngIf="file.extension == '.jpeg'" ></i>
												<i class="icofont-image big" *ngIf="file.extension == '.png'" ></i>
											</div>
												<div class="col-lg-5 file-name">
														{{file?.name}}
													</div>
														<div class="col-lg-4" (click)="downloadFile(file)">
										 	<button class="btn btn-main animated fadeInUp rounded">	Download</button>
													</div>
										</div>

											<div class="share-option mt-5 clearfix">
												<ul class="list-inline">
													<li class="list-inline-item"> <strong>Share:</strong> </li>
													<li class="list-inline-item"><a href="https://www.facebook.com/sharer.php?u=https://blog.ingoforum.ng/{{blog?.slug}}" target="_blank"><i class="icofont-facebook mr-2" ></i>Facebook</a></li>
													<li class="list-inline-item"><a href="https://www.twitter.com/share?url=https://blog.ingoforum.ng/{{blog?.slug}}" target="_blank"><i class="icofont-twitter mr-2"></i>Twitter</a></li>
													<li class="list-inline-item"><a href="https://www.linkedin.com/shareArticle?mini=true&?url=https://blog.ingoforum.ng/{{blog?.slug}}" target="_blank"><i class="icofont-linkedin mr-2"></i>Linkedin</a></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
				<!-- <div class="col-lg-12">
					<form class="comment-form" id="comment-form">
						<h3 class="mb-4">Leave  a comment</h3>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<input class="form-control" type="text" name="name" id="name" placeholder="Name:">
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<input class="form-control" type="text" name="mail" id="mail" placeholder="Email:">
								</div>
							</div>
						</div>

						<textarea class="form-control mb-3" name="comment" id="comment" cols="30" rows="5" placeholder="Comment"></textarea>

						<input class="btn btn-main rounded" type="submit" name="submit-contact" id="submit_contact" value="Submit Message">
					</form>
				</div> -->
			</div>
            </div>
            <div class="col-lg-4">
                <div class="sidebar-wrap mt-5 mt-lg-0">
				      <img src="{{baseUrl}}/public/assets?media={{blog?.image}}" alt="" class="img-fluid w-100">
					</div>
            </div>
        </div>
    </div>
</section>

