import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { ScullyLibModule } from '@scullyio/ng-lib';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BlogDetailComponent } from './pages/blog/detail/detail.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { TruncatePipe } from './pipes/truncate';
@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		BlogComponent,
		ContactComponent,
		BlogDetailComponent,
		HeaderComponent,
		FooterComponent,
		TruncatePipe
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		NgxPaginationModule,
		CommonModule
		// ScullyLibModule,
	],
	providers: [],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
